<template>
  <div class="pa-4">
    <v-img
      class="mx-auto"
      contain
      max-height="150"
      max-width="250"
      src="@/assets/logo.png"
    ></v-img>
    <!-- Name and logout -->
    <v-row class="mt-2">
      <v-col cols="12" sm="6">
        <h2 style="color: #6c4a9e">Welcome {{ userName }},</h2>
      </v-col>
      <v-col cols="12" sm="6" class="text-right">
        <v-btn  v-if="type == 'P'" text color="#6c4a9e" @click="$router.push({ name: 'Home' })"
          >Home</v-btn
        ><v-btn  v-if="type == 'P'" text color="#6c4a9e" @click="$router.push({ name: 'Payment' })"
          >Pay Now</v-btn
        >
        <v-btn text color="error" @click="logout()">Logout</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  components: {},
  methods: {
    ...mapActions(["logoutUser"]),
    logout() {
      this.logoutUser();
      this.$router.push({ name: "Login" });
    },
  },
  props: ["isHome"],
  computed: {
    ...mapGetters(["userName", "type"]),
  },
};
</script>
